<template>
  <div>
    <!--        <div>-->
    <!--            <p class="top">积分及优惠券配置：</p>-->
    <!--        </div>-->

    <div>
      <el-button class="storage" type="primary" @click="submitForm('ruleForm')"
        >保存</el-button
      >
    </div>
    <el-row>
      <el-col :span="24">
        <div>
          <div class="duration">
            <span style="color: red">* </span>
            <span style="width: 500px; margin-left: 5px"
              >转增失效时长配置：</span
            >
            <el-input
              class="input"
              v-model="ruleForm.duration"
              :maxlength="10"
              oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')"
            ></el-input>
            <span style="width: 60px">小时</span>
          </div>

          <!--<div class="duration">
                        <span style="color:red;">* </span>
                        <span style="width: 500px;margin-left: 5px">每人每天可领取优惠券数量：</span>
                        <el-input class="input" v-model="ruleForm.daycoupons" :maxlength="10" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input>
                        <span style="width: 60px">个</span>
                    </div>-->

          <!--<div class="duration">
                        <span style="color:red;">* </span>
                        <span style="width: 500px;margin-left: 5px">活动期间内最多可领优惠券数量：</span>
                        <el-input class="input" v-model="ruleForm.maxcoupons" :maxlength="10" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input>
                        <span style="width: 60px">个</span>
                    </div>-->

          <div class="bottom">
            <div class="spans">
              <span style="color: red">* </span>
              <span class="dom">积分说明配置：</span>
            </div>
            <el-input
              class="inp"
              v-model="ruleForm.pointsdescription"
              :rows="10"
              type="textarea"
              style="height: 300px"
              :maxlength="500"
            >
            </el-input>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive } from "vue";
import { getPointsCouponsDate, updatePointsCouponsDate } from "../http/api";

import { ElMessage } from "element-plus";

export default {
  name: "PointsCoupons",
  setup() {
    const ruleFormRef = ref(null);

    //修改的表单参数
    let ruleForm = reactive({
      id: "",
      duration: "",
      daycoupons: "",
      maxcoupons: "",
      pointsdescription: "",
    });

    let getPointsCouponsDateFun = async () => {
      let PointsCouponsDate = await getPointsCouponsDate();
      ruleForm.duration = PointsCouponsDate[0].duration;
      ruleForm.daycoupons = PointsCouponsDate[0].daycoupons;
      ruleForm.maxcoupons = PointsCouponsDate[0].maxcoupons;
      ruleForm.pointsdescription = PointsCouponsDate[0].pointsdescription;
      ruleForm.id = PointsCouponsDate[0].id;
    };
    getPointsCouponsDateFun(); //加载客服电话

    //提交表单,修改积分及优惠券信息
    let submitForm = async (formName) => {
      //console.log(formName);
      if (
        ruleForm.duration == "" ||
        ruleForm.duration == null ||
        ruleForm.duration == undefined
      ) {
        ElMessage.error({
          message: "转增失效时长不能为空！",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      }

      /*if(ruleForm.duration < 0){
                    ElMessage.error({
                        message: '转增失效时长不能为负数！',
                        type: "error",
                        offset:130,
                        duration:1500
                    });
                    return false;
                }*/
      if (
        ruleForm.daycoupons == "" ||
        ruleForm.daycoupons == null ||
        ruleForm.daycoupons == undefined
      ) {
        ElMessage.error({
          message: "每人每天可领取优惠券数量不能为空！",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      }
      /* if(ruleForm.daycoupons < 0){
                    ElMessage.error({
                        message: '每人每天可领取优惠券数量不能为负数！',
                        type: "error",
                        offset:130,
                        duration:1500
                    });
                    return false;
                }*/
      if (
        ruleForm.maxcoupons == "" ||
        ruleForm.maxcoupons == null ||
        ruleForm.maxcoupons == undefined
      ) {
        ElMessage.error({
          message: "活动期间内最多可领优惠券数量不能为空！",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      }
      /*if(ruleForm.maxcoupons < 0){
                    ElMessage.error({
                        message: '活动期间内最多可领优惠券数量不能为负数！',
                        type: "error",
                        offset:130,
                        duration:1500
                    });
                    return false;
                }*/
      if (
        ruleForm.pointsdescription == "" ||
        ruleForm.pointsdescription == null ||
        ruleForm.pointsdescription == undefined
      ) {
        ElMessage.error({
          message: "积分说明配置不能为空",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      }
      let data = {
        id: ruleForm.id,
        duration: ruleForm.duration,
        daycoupons: ruleForm.daycoupons,
        maxcoupons: ruleForm.maxcoupons,
        pointsdescription: ruleForm.pointsdescription,
      };
      await updatePointsCouponsDate(data);
      ElMessage.success({
        message: "保存成功！",
        type: "success",
        offset: 130,
        duration: 2000,
      });
    };

    return {
      ruleForm,
      submitForm,
      ruleFormRef,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.top {
  width: 300px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 16px;
}
.duration .input {
  margin-right: 0px;
  width: 50%;
}
.duration {
  display: flex;
  align-items: center;
  width: 30%;
  margin-bottom: 25px;
  margin-left: 20px;
  background-color: rgb(242, 242, 242);
  border-radius: 8px;
  > div {
    width: 27%;
    display: flex;
    align-items: center;
    span {
      width: 170px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
.bottom {
  width: 60%;
  height: 100px;
  /*background-color: rgb(242, 242, 242);*/
  margin-left: 20px;
  border-radius: 8px;
}
.bottom .spans {
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: rgb(242, 242, 242);
}
.bottom .dom {
  margin-top: 20px;
  padding-top: 10px;
  width: 300px;
  margin-left: 5px;
}
.bottom .inp {
  width: 100%;
  height: 300px;
}
.storage {
  position: relative;
  display: flex;
  top: 10px;
  left: 1200px;
}
</style>
